
import React from 'react';
import {
	match,
	withRouter
} from 'react-router-dom';
import * as H from 'history';

import usdt_icon from '../../static/pics/coins/usdt.svg';

type MainPageProps = {
	store                 : any,
	match                 : match;
	location              : H.Location,
	history               : H.History,
}
type MainPageState = {
}

class MainPage extends React.Component<MainPageProps, MainPageState> {

	store                 : any;

	render() {

		return (
			<main className="s-main">
				<div className="mt-4">
					<div className="divider right short-20"> </div>
					<div className="container">
					<div className="c-header header-up">
						<div className="h3">ENVELOP Gallery</div>
					</div>
					<div className="lp-filter">
						<div className="row">
						<div className="col col-12 col-md-auto">
							<div>
								<p>Combine Art work and Design masterpieces with the latest wrapped NFTs using Envelop Protocol! BUY your own festive wrapped NFT collectible and send it to your beloved ones, relatives and friends.</p>
							</div>
						</div>
						{/* <div className="col col-5 col-md-auto">
							<div className="lp-filter__sort">
								<label className="input-label">Sort by</label>
								{ this.getSortBlock() }
							</div>
						</div> */}
						</div>
					</div>
					<div className="c-header c-title">
						<div className="h3">Masterpieces</div>
					</div>
					<div className="lp-list">
						<div className="c-row">

							<div className="c-col">
								<div className="w-card w-card-preview">
								<div className="bg">
									<div className="w-card__nft card-title-bold">
										ART 1
									</div>
									<div className="w-card__token">
										<div className="inner"><img className="img" src="" alt="" /></div>
									</div>
									<div className="param-row"><div className="field-label">Collateral</div><div className="field-control"><span> 1 </span></div><div className="field-unit"><img className="coin" src={ usdt_icon } alt="" />USDT</div></div>
								</div>
								<a className="btn btn-red btn-lg" target="_blank" href="https://app.envelop.is/launchpad/#/red1">Buy</a>
								</div>
							</div>

							<div className="c-col">
								<div className="w-card w-card-preview">
								<div className="bg">
									<div className="w-card__nft card-title-bold">
										ART 2
									</div>
									<div className="w-card__token">
										<div className="inner"><img className="img" src="" alt="" /></div>
									</div>
									<div className="param-row"><div className="field-label">Collateral</div><div className="field-control"><span> 2 </span></div><div className="field-unit"><img className="coin" src={ usdt_icon } alt="" />USDT</div></div>
								</div>
								<a className="btn btn-red btn-lg" target="_blank" href="https://app.envelop.is/launchpad/#/red2">Buy</a>
								</div>
							</div>

							<div className="c-col">
								<div className="w-card w-card-preview">
								<div className="bg">
									<div className="w-card__nft card-title-bold">
										ART 3
									</div>
									<div className="w-card__token">
										<div className="inner"><img className="img" src="" alt="" /></div>
									</div>
									<div className="param-row"><div className="field-label">Collateral</div><div className="field-control"><span> 3 </span></div><div className="field-unit"><img className="coin" src={ usdt_icon } alt="" />USDT</div></div>
								</div>
								<a className="btn btn-red btn-lg" target="_blank" href="https://app.envelop.is/launchpad/#/red3">Buy</a>
								</div>
							</div>

						</div>
						<div className="c-row">

							<div className="c-col">
								<div className="w-card w-card-preview">
								<div className="bg">
									<div className="w-card__nft card-title-bold">
										ART 4
									</div>
									<div className="w-card__token">
										<div className="inner"><img className="img" src="" alt="" /></div>
									</div>
									<div className="param-row"><div className="field-label">Collateral</div><div className="field-control"><span> 4 </span></div><div className="field-unit"><img className="coin" src={ usdt_icon } alt="" />USDT</div></div>
								</div>
								<a className="btn btn-red btn-lg" target="_blank" href="https://app.envelop.is/launchpad/#/red1">Buy</a>
								</div>
							</div>

							<div className="c-col">
								<div className="w-card w-card-preview">
								<div className="bg">
									<div className="w-card__nft card-title-bold">
										ART 5
									</div>
									<div className="w-card__token">
										<div className="inner"><img className="img" src="" alt="" /></div>
									</div>
									<div className="param-row"><div className="field-label">Collateral</div><div className="field-control"><span> 5 </span></div><div className="field-unit"><img className="coin" src={ usdt_icon } alt="" />USDT</div></div>
								</div>
								<a className="btn btn-red btn-lg" target="_blank" href="https://app.envelop.is/launchpad/#/red2">Buy</a>
								</div>
							</div>

							<div className="c-col">
								<div className="w-card w-card-preview">
								<div className="bg">
									<div className="w-card__nft card-title-bold">
										ART 6
									</div>
									<div className="w-card__token">
										<div className="inner"><img className="img" src="" alt="" /></div>
									</div>
									<div className="param-row"><div className="field-label">Collateral</div><div className="field-control"><span> 6 </span></div><div className="field-unit"><img className="coin" src={ usdt_icon } alt="" />USDT</div></div>
								</div>
								<a className="btn btn-red btn-lg" target="_blank" href="https://app.envelop.is/launchpad/#/red3">Buy</a>
								</div>
							</div>

						</div>
					</div>

					<div className="c-header c-title">
						<div className="h3">Envelop Art</div>
					</div>
					<div className="lp-list">
						<div className="c-row">

							<div className="c-col">
								<div className="w-card w-card-preview">
								<div className="bg">
									<div className="w-card__nft card-title-bold">
										ART 1
									</div>
									<div className="w-card__token">
										<div className="inner"><img className="img" src="" alt="" /></div>
									</div>
									<div className="param-row"><div className="field-label">Collateral</div><div className="field-control"><span> 1 </span></div><div className="field-unit"><img className="coin" src={ usdt_icon } alt="" />USDT</div></div>
								</div>
								<a className="btn btn-red btn-lg" target="_blank" href="https://app.envelop.is/launchpad/#/red1">Buy</a>
								</div>
							</div>

							<div className="c-col">
								<div className="w-card w-card-preview">
								<div className="bg">
									<div className="w-card__nft card-title-bold">
										ART 2
									</div>
									<div className="w-card__token">
										<div className="inner"><img className="img" src="" alt="" /></div>
									</div>
									<div className="param-row"><div className="field-label">Collateral</div><div className="field-control"><span> 2 </span></div><div className="field-unit"><img className="coin" src={ usdt_icon } alt="" />USDT</div></div>
								</div>
								<a className="btn btn-red btn-lg" target="_blank" href="https://app.envelop.is/launchpad/#/red2">Buy</a>
								</div>
							</div>

							<div className="c-col">
								<div className="w-card w-card-preview">
								<div className="bg">
									<div className="w-card__nft card-title-bold">
										ART 3
									</div>
									<div className="w-card__token">
										<div className="inner"><img className="img" src="" alt="" /></div>
									</div>
									<div className="param-row"><div className="field-label">Collateral</div><div className="field-control"><span> 3 </span></div><div className="field-unit"><img className="coin" src={ usdt_icon } alt="" />USDT</div></div>
								</div>
								<a className="btn btn-red btn-lg" target="_blank" href="https://app.envelop.is/launchpad/#/red3">Buy</a>
								</div>
							</div>

						</div>
						<div className="c-row">

							<div className="c-col">
								<div className="w-card w-card-preview">
								<div className="bg">
									<div className="w-card__nft card-title-bold">
										ART 4
									</div>
									<div className="w-card__token">
										<div className="inner"><img className="img" src="" alt="" /></div>
									</div>
									<div className="param-row"><div className="field-label">Collateral</div><div className="field-control"><span> 4 </span></div><div className="field-unit"><img className="coin" src={ usdt_icon } alt="" />USDT</div></div>
								</div>
								<a className="btn btn-red btn-lg" target="_blank" href="https://app.envelop.is/launchpad/#/red1">Buy</a>
								</div>
							</div>

							<div className="c-col">
								<div className="w-card w-card-preview">
								<div className="bg">
									<div className="w-card__nft card-title-bold">
										ART 5
									</div>
									<div className="w-card__token">
										<div className="inner"><img className="img" src="" alt="" /></div>
									</div>
									<div className="param-row"><div className="field-label">Collateral</div><div className="field-control"><span> 5 </span></div><div className="field-unit"><img className="coin" src={ usdt_icon } alt="" />USDT</div></div>
								</div>
								<a className="btn btn-red btn-lg" target="_blank" href="https://app.envelop.is/launchpad/#/red2">Buy</a>
								</div>
							</div>

							<div className="c-col">
								<div className="w-card w-card-preview">
								<div className="bg">
									<div className="w-card__nft card-title-bold">
										ART 6
									</div>
									<div className="w-card__token">
										<div className="inner"><img className="img" src="" alt="" /></div>
									</div>
									<div className="param-row"><div className="field-label">Collateral</div><div className="field-control"><span> 6 </span></div><div className="field-unit"><img className="coin" src={ usdt_icon } alt="" />USDT</div></div>
								</div>
								<a className="btn btn-red btn-lg" target="_blank" href="https://app.envelop.is/launchpad/#/red3">Buy</a>
								</div>
							</div>

						</div>
					</div>


					<div className="c-header c-title">
						<div className="h3">Envelop Partners</div>
					</div>
					<div className="lp-list">
						<div className="c-row">

							<div className="c-col">
								<div className="w-card w-card-preview">
								<div className="bg">
									<div className="w-card__nft card-title-bold">
										ART 1
									</div>
									<div className="w-card__token">
										<div className="inner"><img className="img" src="" alt="" /></div>
									</div>
									<div className="param-row"><div className="field-label">Collateral</div><div className="field-control"><span> 1 </span></div><div className="field-unit"><img className="coin" src={ usdt_icon } alt="" />USDT</div></div>
								</div>
								<a className="btn btn-red btn-lg" target="_blank" href="https://app.envelop.is/launchpad/#/red1">Buy</a>
								</div>
							</div>

							<div className="c-col">
								<div className="w-card w-card-preview">
								<div className="bg">
									<div className="w-card__nft card-title-bold">
										ART 2
									</div>
									<div className="w-card__token">
										<div className="inner"><img className="img" src="" alt="" /></div>
									</div>
									<div className="param-row"><div className="field-label">Collateral</div><div className="field-control"><span> 2 </span></div><div className="field-unit"><img className="coin" src={ usdt_icon } alt="" />USDT</div></div>
								</div>
								<a className="btn btn-red btn-lg" target="_blank" href="https://app.envelop.is/launchpad/#/red2">Buy</a>
								</div>
							</div>

							<div className="c-col">
								<div className="w-card w-card-preview">
								<div className="bg">
									<div className="w-card__nft card-title-bold">
										ART 3
									</div>
									<div className="w-card__token">
										<div className="inner"><img className="img" src="" alt="" /></div>
									</div>
									<div className="param-row"><div className="field-label">Collateral</div><div className="field-control"><span> 3 </span></div><div className="field-unit"><img className="coin" src={ usdt_icon } alt="" />USDT</div></div>
								</div>
								<a className="btn btn-red btn-lg" target="_blank" href="https://app.envelop.is/launchpad/#/red3">Buy</a>
								</div>
							</div>

						</div>
						<div className="c-row">

							<div className="c-col">
								<div className="w-card w-card-preview">
								<div className="bg">
									<div className="w-card__nft card-title-bold">
										ART 4
									</div>
									<div className="w-card__token">
										<div className="inner"><img className="img" src="" alt="" /></div>
									</div>
									<div className="param-row"><div className="field-label">Collateral</div><div className="field-control"><span> 4 </span></div><div className="field-unit"><img className="coin" src={ usdt_icon } alt="" />USDT</div></div>
								</div>
								<a className="btn btn-red btn-lg" target="_blank" href="https://app.envelop.is/launchpad/#/red1">Buy</a>
								</div>
							</div>

							<div className="c-col">
								<div className="w-card w-card-preview">
								<div className="bg">
									<div className="w-card__nft card-title-bold">
										ART 5
									</div>
									<div className="w-card__token">
										<div className="inner"><img className="img" src="" alt="" /></div>
									</div>
									<div className="param-row"><div className="field-label">Collateral</div><div className="field-control"><span> 5 </span></div><div className="field-unit"><img className="coin" src={ usdt_icon } alt="" />USDT</div></div>
								</div>
								<a className="btn btn-red btn-lg" target="_blank" href="https://app.envelop.is/launchpad/#/red2">Buy</a>
								</div>
							</div>

							<div className="c-col">
								<div className="w-card w-card-preview">
								<div className="bg">
									<div className="w-card__nft card-title-bold">
										ART 6
									</div>
									<div className="w-card__token">
										<div className="inner"><img className="img" src="" alt="" /></div>
									</div>
									<div className="param-row"><div className="field-label">Collateral</div><div className="field-control"><span> 6 </span></div><div className="field-unit"><img className="coin" src={ usdt_icon } alt="" />USDT</div></div>
								</div>
								<a className="btn btn-red btn-lg" target="_blank" href="https://app.envelop.is/launchpad/#/red3">Buy</a>
								</div>
							</div>

						</div>
					</div>

					<div className="lp-list">

						<div className="c-row">
							<div className="c-p info-steps">
								<div className="info-step info-step-1">
									<p>1. Buy wNFT from ENVELOP ART Collection</p>
								</div>
								<div className="info-step info-step-2">
									<p>2. Upgrade - add collateral OR just skip this step</p>
									<a className="btn btn-lg" target="_blank" href="https://app.envelop.is/">Upgrade</a>
								</div>
								<div className="info-step info-step-3">
									<p>3. Send your wNFT to any place</p>
									<a className="btn btn-lg" target="_blank" href="https://app.envelop.is/">Send</a>
								</div>
							</div>
						</div>
						<div className="c-row">
							<div className="c-p">
								<p>Disclaimer: Here you can buy ...</p>
							</div>
						</div>
				</div>
				</div>
				</div>

			</main>
		)
	}
}

export default withRouter(MainPage);