import React                   from 'react';
import {
	Link,
	withRouter,
	match
} from 'react-router-dom';
import * as H from 'history';

import icon_logo      from '../../static/pics/logo.svg';
import youtube_logo      from '../../static/pics/socials/youtube.svg';
import in_logo      from '../../static/pics/socials/in.svg';
import github_logo      from '../../static/pics/socials/github.svg';
import telegram_logo      from '../../static/pics/socials/telegram.svg';
import cntelegram_logo      from '../../static/pics/socials/cn-telegram.svg';
import twitter_logo      from '../../static/pics/socials/twitter.svg';
import m_logo      from '../../static/pics/socials/m.svg';


import BigNumber from 'bignumber.js';
BigNumber.config({ DECIMAL_PLACES: 50, EXPONENTIAL_AT: 100});

type HeaderProps = {
	store                 : any,
	match                 : match;
	location              : H.Location,
	history               : H.History,
}
type HeaderState = {
}

class Header extends React.Component<HeaderProps, HeaderState> {

	store                 : any;

	render() {
		return (
			<header className="s-header">
			<div className="container">
				<div>
					<Link className="s-header__logo" to="/">
						<img src={ icon_logo } alt="ENVELOP" />
					</Link>
					
				</div>
				<div className="s-user">
					<ul className="socials d-lg-flex">
						<li><a href="https://www.youtube.com/c/ENVELOP" target="_blank"><img src={ youtube_logo } alt="ENVELOP. NFTs YouTube Channel" /></a></li>
						<li><a href="https://www.linkedin.com/company/niftsy" target="_blank"><img src={ in_logo } alt="NIFTSY is token" /></a></li>
						<li><a href="https://github.com/niftsy/niftsysmarts" target="_blank"><img src={ github_logo } alt="Github of our NFT project" /></a></li>
						<li><a href="https://t.me/envelop_en" target="_blank"><img src={ telegram_logo } alt="ENVELOP telegram group" /></a></li>
						<li><a href="https://t.me/envelop_cn" target="_blank"><img src={ cntelegram_logo } alt="ENVELOP telegram group (China)" /></a></li>
						<li><a href="https://twitter.com/Envelop_project" target="_blank"><img src={ twitter_logo } alt="Our twitter" /></a></li>
						<li><a href="https://envelop.medium.com/" target="_blank"><img src={ m_logo } alt="Blog about Web 3.0" /></a></li>
					</ul>
				</div>
			</div>
			</header>

		)
	}
}

export default withRouter(Header);