import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/App';
import './static/css/styles.min.css';
import './static/css/styles_art.css';

ReactDOM.render(
  <React.StrictMode>
      <App />
  </React.StrictMode>,
  document.getElementById('root')
);
